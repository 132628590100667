import { PlaceData } from "remote";

const stub: PlaceData = {
  facebook_url: 'https://www.facebook.com/Kieuauto/',
  physical_address: [
    '2140 McPhillips St Unit 4',
    'Winnipeg, MB R2V 3P4',
  ],
  service_phone_number: '(204) 615-9858',
  sales_phone_number: '(204) 249-0552',
  mobile_phone_number: '(204) 962-2689',
  email_address: 'kieuautoservice@gmail.com', //'john@kieuauto.ca',
  services: [
    "Oil Changes",
    "Tires",
    "Brakes",
    "Safeties",
    "Alignments",
    "Suspension",
    "Heating & AC",
    "Electrical",
    "Engines",
    "Transmissions",
    "Drivetrain"
  ],
  name: 'Kieu Auto Service',
  lat: 49.9490798,
  lng: -97.1535586,
  formatted_address: "2140 McPhillips St Unit 4, Winnipeg, MB R2V 3P4, Canada",
  url: "https://maps.google.com/?cid=11615908898229480515",
  address_components: [
    "2140 McPhillips St Unit 4",
    "Winnipeg, MB R2V 3P4",
    "Canada"
  ],
  hours: [
    "Monday: 8:00 am - 6:00 pm",
    "Tuesday: 8:00 am - 6:00 pm",
    "Wednesday: 8:00 am - 6:00 pm",
    "Thursday: 8:00 am - 6:00 pm",
    "Friday: 8:00 am - 6:00 pm",
    "Saturday: Closed",
    "Sunday: Closed",
  ],
  formatted_hours: [
    "Monday to Friday: 8:00 am - 6:00 pm",
    "Sunday and Saturday: Closed"
  ],
  open: true,
  reviews: [
    {
      author_name: 'Wong Kings',
      author_url: "https://www.google.com/maps/contrib/117955009176819668925/reviews",
      profile_photo_url: "https://lh3.googleusercontent.com/a-/AD5-WCmv4Jcsw0KyFlt_1XSmMbfqn7uPqmbPFy4FWI2A5SM=s128-c0x00000000-cc-rp-mo-ba5",
      rating: 5,
      relative_time_description: "2 months ago",
      text: "Kieu can accommodate your need at his best. He never tried to upsales anything which is best ! He even did small top ups without any charge. Good service!!!",
    },
    {
      author_name: "Jerry",
      author_url: "https://www.google.com/maps/contrib/104803802000736137563/reviews",
      profile_photo_url: "https://lh3.googleusercontent.com/a-/AD5-WClSnIcmKHCtHqV1BxACGmapiYbwn1b9QHXAaIw_Zw=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "a month ago",
      text: "They gave us an honest assessment and recommendation - we know because it was the same as a few other mechanics.  Charged a fair price and ever pressured us to perform unnecessary work! They gave us an honest assessment and recommendation - we know because it was the same as a few other mechanics.  Charged a fair price and ever pressured us to perform unnecessary work!",
    },    
    {
      author_name: "Chelsey Turner",
      author_url: "https://www.google.com/maps/contrib/107257615162240849922/reviews",
      profile_photo_url: "https://lh3.googleusercontent.com/a/AEdFTp6PJgZnT6FwVsebBM75zQRA1GFExUPaBmJfnIA=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "2 months ago",
      text: "KIEU Auto are very honest, professional and were great to work with. I highly recommend them.",
    },
    {
      author_name: "PFAB Building Systems",
      author_url: "https://www.google.com/maps/contrib/102759182116371748640/reviews",
      profile_photo_url: "https://lh3.googleusercontent.com/a/AEdFTp6Szh4URKP59rNIuMB-HRC1AKyETg1B30u9H17u=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "2 months ago",
      text: "Great place to fix your car, fair prices, fast friendly service.",
    },
    {
      author_name: "Michael Duong",
      author_url: "https://www.google.com/maps/contrib/115294411203793248347/reviews",
      profile_photo_url: "https://lh3.googleusercontent.com/a/AEdFTp6H1cBX0t9CU6it4V-LE_lLrHys5DH1rT6pNxAV=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "a month ago",
      text: "Excellent service, reasonable rates, reliable mechanics. They are busy for a reason.",
    },
  ],
  user_ratings_total: 88,
  rating: 4.7,
}

export default stub;